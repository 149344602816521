
import { Component, Prop, Vue, Mixins } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import tableCheck from "@/mixins/table-check";
import TableEnableFlag from "@/components/scope/table-enable-flag.vue";
import { emptyToLine } from "@/utils/common";
import dayjs from "dayjs";
import { Pageniation, Loading } from "@/utils/decorator";
import t from "@common/src/i18n/t";
const announcement = namespace("announcement");
const base = namespace("base");
const consult = namespace("consult");

@Component({
  components: { TableEnableFlag }
})
@Pageniation("loadListData")
export default class Announcement extends Mixins(tableCheck) {
  @base.Action getDictionaryList;
  @announcement.Action getAnnouncementList;
  @announcement.Action endAnnouncement;
  @consult.Action queryUserAccountList;
  dayjs = dayjs;
  visible: boolean = true;
  searchForm = {
    title: "", //公告名称
    announcementCode: "", //公告编号
    status: "",
    beginTime: "",
    endTime: "",
    announcementRange: "", //发布范围
    publisher: "" //发布人
  };
  emptyToLine = emptyToLine;
  announcementList: any[] = [];
  storeTypeList: any[] = [];
  publisherList: any = [];
  get searchList() {
    return [
      {
        label: t("v210831.announcement-name-not-flag"),
        type: "input",
        value: "",
        prop: "title"
      },
      {
        label: t("v210831.announcement-no"),
        type: "input",
        value: "",
        prop: "announcementCode"
      },
      {
        label: t("v210801.state"),
        type: "select",
        selectList: this.statusList,
        value: "",
        prop: "status"
      },
      {
        label: t("v210831.announcement-date"),
        type: "datetimerange",
        value: "",
        prop: "Time"
      },
      {
        label: t("v210831.release-scope"),
        type: "select",
        selectList: this.storeTypeList,
        value: "",
        prop: "announcementRange"
      },
      {
        label: t("v210831.publisher"),
        type: "select",
        selectList: this.publisherList,
        value: "",
        prop: "publisher"
      }
    ];
  }
  //多行文本省略
  filters(value) {
    if (!value) return "";
    if (value.length > 20) {
      return value.slice(0, 20) + "...";
    }
    return value;
  }
  handlefinished(finishdata) {
    this.endAnnouncement(finishdata).then(() => {
      this.$message.success(t("v210831.operation-succeeded"));
      this.loadListData();
    });
  }
  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        if (item.prop === "Time") {
          this.searchForm.beginTime = item.value ? item.value[0] : "";
          this.searchForm.endTime = item.value ? item.value[1] : "";
        }
        this.searchForm[item.prop] = item.value;
      }
    });
    console.log(this.searchForm, "searchfrom");

    this.loadListData();
  }
  created() {
    this.init();
  }
  @Loading()
  loadListData() {
    return this.getAnnouncementList({
      ...this.searchForm,
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize
    }).then(data => {
      this.announcementList = data.data.list;
      //   console.log(data, "data");
      return data;
    });
  }
  async init() {
    this.loadListData();
    this.getDictionaryList("store_type").then(data => {
      this.storeTypeList = data;
    });
    //获取发布人列表
    this.getUserAccount();
  }
  getUserAccount() {
    this.queryUserAccountList({
      pageNum: 1,
      pageSize: 999
    }).then(data => {
      this.publisherList = data.data.list
        ? data.data.list.map(item => {
            item.label = item.firstName;
            item.value = item.firstName;
            return item;
          })
        : [];
    });
  }
  get statusList() {
    return [
      {
        label: t("v210831.not-started"),
        value: 0
      },
      {
        label: t("v210831.in-the-announcement"),
        value: 1
      },
      {
        label: t("v210831.has-ended"),
        value: 2
      }
    ];
  }
  handleEdit(row) {
    this.$router.push(`/management/announcement-edit/${row.announcementCode}`);
  }
}
